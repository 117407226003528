<template>
  <b-row class="match-height">
    <b-col
      v-for="(item, index) in items"
      :key="`totalizer-${index}`"
      class="totalizer-card"
    >
      <b-card no-body class="earnings-card">
        <b-card-header>
          <h4 class="mb-0 mouse-pointer" @click="filterByChart(item.data)">
            {{ item.name }}
          </h4>
          <b-card-text class="mb-0 totalizer-help">
            <b-button
              :id="`help-totalizer-${index}`"
              variant="flat"
              class="btn-icon"
              @click="getGroupStatusNames(item.data)"
            >
              <feather-icon
                icon="HelpCircleIcon"
                size="21"
                class="text-muted cursor-pointer"
              />
            </b-button>
            <b-popover :target="`help-totalizer-${index}`" triggers="focus">
              <template #title>
                <span>{{ item.name }}</span>
              </template>
              <template v-for="(name, index) in statusNames">
                <b>{{ statusTitle[index] }}:</b> {{ name }};<br />
              </template>
            </b-popover>
          </b-card-text>
        </b-card-header>
        <b-card-body class="mouse-pointer" @click="filterStatus(item.data)">
          <!-- apex chart -->
          <template v-if="processChart(item)">
            <div>
              <vue-apex-charts
                height="130"
                :options="item.options"
                :series="item.series"
              />
            </div>
          </template>
          <div v-if="loading" class="position-absolute spinner-position">
            <b-spinner variant="primary" />
          </div>
        </b-card-body>
        <b-row class="text-center mx-0">
          <b-col
            cols="6"
            class="border-top border-right d-flex align-items-between flex-column py-1"
            style="padding-left: 1px; padding-right: 1px"
          >
            <b-card-text class="text-muted mb-0" style="font-size: smaller">
              Faturamento
            </b-card-text>
            <div class="font-weight-bolder mb-0">
              {{ item.total | toCurrency }}
            </div>
          </b-col>
          <b-col
            cols="6"
            class="border-top d-flex align-items-between flex-column py-1"
          >
            <b-card-text class="text-muted mb-0" style="font-size: smaller">
              Quantidade
            </b-card-text>
            <div class="font-weight-bolder mb-0">
              {{ item.quantity }}
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BSpinner,
  BPopover,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapActions } from "vuex";
import * as types from "../store/types";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton,
    BSpinner,
  },
  props: {
    totalizers: {
      type: Array,
      default: () => [],
    },
    aggroupments: {
      type: Array,
      default: () => [],
    },
    filterStatus: Function
  },
  data() {
    return {
      loading: false,
      statusNames: [],
      statusTitle: [],
      status: [],
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      statusList: types.STATUS_LIST,
    }),
    items: function () {
      return this.aggroupments;
    },
  },
  methods: {
    ...mapActions({
      searchContract: types.SEARCH_CONTRACTS,
    }),

    search(currentPage) {
      this.currentPage = currentPage;
      this.loading = true;
      this.searchContract({
        status: this.status,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao filtrar os contratos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async filterByChart(id) {
      this.status = id.map((e) => {
        return e;
      });
      await this.search();
    },
    processChart(item) {
      item.series = []; //array de percentual
      item.labels = [];
      item.slice_colors = [];
      item.total = 0;
      item.total_percentage = 0;
      item.quantity = 0;
      item.data.forEach((status_id, index) => {
        var val = this.totalizers.find(
          (e) => e.id_contract_status == status_id
        );
        if (val) {
          item.series.push(val.percentage);
          item.slice_colors.push(item.colors[index]);
          item.labels.push(val.description);
          item.total += val.gross_value;
          item.total_percentage += val.percentage;
          item.quantity += val.quantity;
        }
      });
      if (item.total_percentage > 100) {
        item.total_percentage = 100;
      }
      item.options = {
        chart: {
          type: "donut",
          toolbar: {
            show: false,
          },
          width: "100%",
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        labels: item.labels,
        colors: item.slice_colors,
        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: function (value, series) {
              // use series argument to pull original string from chart data
              return Math.round(
                item.quantity * (value / item.total_percentage)
              );
            },
          },
        },
        stroke: { width: 0 },
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                  formatter() {
                    return "Total";
                  },
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    return `${parseFloat(val)}%`;
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: "Total",
                  formatter() {
                    return parseFloat(item.total_percentage.toFixed(2)) + "%";
                  },
                },
              },
            },
          },
        },
      };
      return item.quantity > 0;
    },
    getGroupStatusNames(list) {
      this.statusNames = this.statusList
        .filter((e) => list.includes(e.id.toString()))
        .map((e) => e.description);
      this.statusTitle = this.statusList
        .filter((e) => list.includes(e.id.toString()))
        .map((e) => e.name);
    },
  },
};
</script>

<style lang="scss">
.totalizer-card {
  min-width: 250px;
  max-width: 500px !important;
  width: max-content !important;
}
.totalizer-help {
  position: absolute;
  top: 10px;
  right: 10px;
}

.mouse-pointer {
  cursor: pointer;
}

.spinner-position {
  bottom: 80px;
  right: 15px;
}
</style>
