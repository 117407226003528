<template>
  <div>
    <b-sidebar
      id="sidebar-edit-snapshot"
      sidebar-class="sidebar-lg"
      :visible="editContractOwnerSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateEditContractOwnerSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Editar dono do contrato {{editContractOwnerSidebar.id}}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <template>
          <!-- Form -->
          <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
            class="p-1"
          >
            <b-row>
              <b-col class="mt-1">
                <b-form-group
                  label="Novo dono"
                  label-for="contract-edit-new-owner-id"
                >
                  <v-select
                    id="contract-edit-new-owner-id"
                    v-model="newConsultant"
                    :options="consultants"
                    :reduce="(consultant) => consultant.id"
                    :loading="loading"
                    label="name"
                    :class="getSelectErrorClass(v$.newConsultant.$error)"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.newConsultant.required.$invalid">
                      Você deve informar um novo dono
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1">
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    :disabled="saving"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ saving ? "Salvando..." : "Salvar" }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BSidebar, BForm, BFormGroup, BButton, BFormInput, BRow, BCol, BSpinner
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useToast } from 'vue-toastification/composition'
import { getVueSelectErrorClass } from "@/helpers/validators";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'


export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BSpinner,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      newConsultant: undefined,
      loading: false,
      saving: false,
    }
  },
  computed: {
    ...mapGetters({
      editContractOwnerSidebar: types.EDIT_CONTRACT_OWNER_SIDEBAR,
      consultants: sharedTypes.ACTIVE_CONSULTANTS
    }),
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate()};
  },
  validations() {
    return {
      newConsultant: { required },
    }
  },
  mounted() {
    this.loading = true
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    ...mapMutations({
      mutateEditContractOwnerSidebar: types.MUTATE_EDIT_CONTRACT_OWNER_SIDEBAR
    }),
    ...mapActions({
      updateContractOwner: types.UPDATE_CONTRACT_OWNER,
      getConsultants: sharedTypes.GET_ACTIVE_CONSULTANTS
    }),
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true
      this.updateContractOwner({
        contract_id: this.editContractOwnerSidebar.id,
        consultant_id: this.newConsultant
      })
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O contrato foi alterado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.editContractOwnerSidebar.saveAction()
          this.mutateEditContractOwnerSidebar({ visible: false })
        })
        .catch(err => {
          if(err.response.status == 405){
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar o contrato. " + err.response.data,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }else{
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Oops!',
                text: 'Ocorreu um erro ao salvar o contrato. Entre em contato com o setor de TI.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.saving = false;
        });
      
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    clear(){
      this.newConsultant = undefined
      this.v$.$reset();
    }
  }
}
</script>
